const navLinks = document.querySelectorAll('.navigation__container-ul-li a');

navLinks.forEach(link => {
    link.addEventListener('click', function() {
        // Zuerst bei allen Links die Klasse "active" entfernen
        navLinks.forEach(link => link.classList.remove('active'));

        // Füge der angeklickten Sprungmarke die Klasse "active" hinzu
        this.classList.add('active');
    });
});


// Hole den Button
const scrollToTopBtn = document.getElementById("scrollToTopBtn");

// Überwache das Scroll-Event
window.onscroll = function() {
    // Zeige den Button an, wenn der Benutzer weiter als 400px scrollt
    if (document.body.scrollTop > 1500 || document.documentElement.scrollTop > 1500) {
        scrollToTopBtn.style.display = "block";
    } else {
        scrollToTopBtn.style.display = "none";
    }
};

// Funktion für den Button, um nach oben zu scrollen
scrollToTopBtn.addEventListener('click', function() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Weiches Scrollen nach oben
    });
});


document.addEventListener("DOMContentLoaded", function () {
    const menuToggle = document.getElementById('menu-toggle');
    const navLinks = document.getElementById('nav-links');

    menuToggle.addEventListener('click', function () {
        navLinks.classList.toggle('active');
        menuToggle.classList.toggle('active');
    });
});


document.addEventListener("DOMContentLoaded", function () {
    const topbar = document.querySelector('.topbar');
    const navigation = document.querySelector('.navigation');
    const logo = document.querySelector('.col-2-div img');

    window.addEventListener('scroll', function () {
        if (window.scrollY > 70) { // Scroll threshold for shrinking effect
            topbar.classList.add('scrolled');
            navigation.classList.add('scrolled');
        } else {
            topbar.classList.remove('scrolled');
            navigation.classList.remove('scrolled');
        }
    });
});